@font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../fonts/poppins/Poppins-SemiBold.ttf");
}

.new-brand-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #4072c9;
  background-clip: border-box;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
  margin-bottom: 20px;
}

.new-brand-card h5 {
  font-family: Poppins-Regular;
}

.new-btn-card-selected {
  -webkit-box-shadow: 0 0 20px #777777;
  box-shadow: 0 0 20px #777777;
  background-color: #345ea8;
}

.badge-discount {
  background-color: #3db9c5 !important;
}

.new-brand-footer {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #3db9c5;
  background-clip: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 44px;
}

.new-brand-body {
  position: absolute;
  top: 8%;
  align-self: center;
  width: 16.5rem;
}

.img-consulta {
  margin: 0 auto;
  max-width: 500px;
}

.new-brand-footer-text {
  font-size: 18px;
  line-height: 21px;
  color: #fefefe;
  margin: 0;
  position: absolute;
  top: 25%;
  align-self: center;
}

.new-btn-card {
  width: 300px;
  min-height: 180px;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-transition: -webkit-box-shadow 0.7s;
  transition: -webkit-box-shadow 0.7s;
  transition: box-shadow 0.7s;
  transition: box-shadow 0.7s, -webkit-box-shadow 0.7s;
  margin: 0 auto;
  margin-bottom: 1rem;
  border-bottom: 5px solid #3db9c5;
}

/* .new-btn-card:hover {
  -webkit-box-shadow: 0 0 20px #777777;
  box-shadow: 0 0 20px #777777;
} */

.text-white {
  color: #ffff;
}

.price-de {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: line-through;

  color: rgba(254, 254, 254, 0.5);
}

.price-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;

  color: #fefefe;
}

.price-description {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #fefefe;
}

.price-por {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
  color: #fefefe;
}

.pt-25 {
  padding-top: 2.5rem !important;
}

.card-plans {
  width: 300px;
  height: 490px;
  background: #345ea8;
  cursor: pointer;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-transition: -webkit-box-shadow 0.7s;
  transition: -webkit-box-shadow 0.7s;
  transition: box-shadow 0.7s;
  transition: box-shadow 0.7s, -webkit-box-shadow 0.7s;
  border-bottom: 5px solid #3db9c5;
}

.header-text-plans {
  position: absolute;
  color: white;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  top: 1.3rem;
}

.header-img-plans {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.apartir-de {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.price {
  font-weight: bold;
  font-size: 29px;
  line-height: 37px;
}

.price-nacional {
  font-weight: bold;
  font-size: 28px;
  line-height: 37px;
  color: #ffffff;
}

.description-price {
  top: 8rem;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.description-list-price {
  top: 13rem;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.separator-description-plans {
  border: 1px solid #4eb9c5;
  position: absolute;
  top: 21.5rem;
  width: 100%;
}

.description-list-elebilidade {
  position: absolute;
  top: 23rem;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.card-plans:hover {
  -webkit-box-shadow: 0 0 15px #777777;
  box-shadow: 0 0 15px #777777;
}

.group-card-plans {
  display: flex;
  overflow-x: auto;
}

.owl-stage .active {
  /* margin-left: 1.5rem; */
  display: flex;
  justify-content: center;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  display: none;
}

.left-icon {
  position: absolute;
  z-index: 1500;
  top: 41%;
  left: 0.7rem;
  cursor: pointer;
}

.right-icon {
  cursor: pointer;
  position: absolute;
  z-index: 1500;
  top: 41%;
  right: 0.7rem;
}

/*  */
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
}
.inputGroup label {
  padding: 12px 30px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  -webkit-transition: color 200ms ease-in;
  transition: color 200ms ease-in;
  overflow: hidden;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #345ea8;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroup label:after {
  width: 20px;
  height: 20px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 0.5px 1px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  color: #fff;
}
.inputGroup input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #3db9c5;
  border-color: #3db9c5;
}
.inputGroup input {
  width: 32px;
  height: 32px;
  -webkit-box-ordinal-group: 2;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.image-price {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.preco-adesao {
  font-size: 16px;
  line-height: 20px;
  color: #fefefe;
  top: 1.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preco-mensalidade {
  color: #fefefe;
  top: 3.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
}
.mes {
  color: #fefefe;
  top: 5.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.btn-forma-pagamento {
  color: #fff !important;
  background-color: #345EA8 !important;
  border-color: #345EA8;
  border-radius: 0px;
  padding: 15px 0px;
  font-weight: bold;
  width: 17rem;
}

.btn-forma-pagamento-up-active {
  color: #ffff !important;
  border-color: #dc3545 !important;
  background-color: #dc3545 !important;
  border-radius: 0px;
  font-weight: bold;
  width: 17rem;
  cursor: auto !important;
  border-bottom: 0px solid !important;
}

.btn-forma-mais-vendido-up-active {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
  background-color: transparent !important;
  border-radius: 0px;
  font-weight: bold;
  width: 17rem;
  cursor: auto !important;
  border-top: 0px solid !important;
}

.text-payment-up-active {
  font-size: 18px !important;
}

.btn-forma-pagamento-up {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent;
  border-radius: 0px;
  width: 17rem;
}

.btn-forma-pagamento:hover {
  background-color: #3db9c5 !important;
  text-decoration: underline;
}

.btn-forma-pagamento-active {
  color: #fff;
  background-color: #3db9c5;
  border-color: #459343;
  border-radius: 0px;
  padding: 15px 0px;
  font-weight: bold;
  width: 17rem;
  text-decoration: underline;
}

.btn-forma-pagamento-active:hover {
  text-decoration: underline;
}

.center-group-button {
  display: flex;
  justify-content: center;
}

.text-description-pagamento {
  color: #345ea8;
  font-size: 16px;
  line-height: 20px;
  display: flex;
}

.text-description-pagamento-selected {
  color: #3db9c5;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  font-weight: bold;
  text-decoration: underline;
}

.circle-text-description-anual {
  height: 12px;
  width: 12px;
  background: #3db9c5;
  border-radius: 50%;
}

.circle-text-description-mensal {
  height: 12px;
  width: 12px;
  background: #98b7ee;
  border-radius: 50%;
}

.separator-desc-pagamento {
  padding-right: 4rem;
}

.badge {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #ffff;
}

.badge-pagamento-anual {
  background-color: #3db9c5;
}

.badge-pagamento-mensal {
  background-color: #355da8;
}

.badge-pagamento-green {
  background-color: #dc3545;
}

@media (max-width: 620px) {
  .btn-forma-pagamento {
    width: 15rem;
  }
  .btn-forma-pagamento-active {
    width: 15rem;
  }

  .btn-forma-pagamento-up-active {
    width: 15rem;
  }

  .btn-forma-mais-vendido-up-active {
    width: 15rem;
  }

  .btn-forma-pagamento-up {
    width: 15rem;
  }
}

.description-pagamento {
  text-align: center;
  justify-content: center;
}


.form-credit-card {
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
}

.group-vencimento {
  justify-content: center;
}

.btn-vencimentos {
  width: 126px;
  background-color: #355da8 !important;
  border-color: #355da8;
  color: #ffff;
  font-weight: bold;
}

.btn-vencimentos:hover {
  background-color: #3db9c5 !important;
  text-decoration: underline;
}

.btn-vencimentos-active {
  width: 126px;
  background-color: #3db9c5;
  border-color: #3db9c5;
  color: #ffff;
  font-weight: bold;
  text-decoration: underline!important;
}

.btn-vencimentos-active:hover {
  text-decoration: underline;
}

.button-tipo {
  cursor: pointer;
  border: 1.5px solid #6286c5;
  padding: 0.5rem;
  border-radius: 5px;
}

.button-tipo:hover {
  border: 1.5px solid #3667a7;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.termos-aceite {
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}

/* final section */
.final-container {
  margin-top: 0;
  width: 70%;
  /* padding-left: 10rem;
  padding-right: 10rem; */
}

.first-section {
  margin-top: 0;
  width: 100% !important;
  /* padding-left: 10rem;
  padding-right: 10rem; */
  background: linear-gradient(to bottom, #325f98, #3babbc);
  border-radius: 0;
}

.video-section {
  margin-top: 0;
  width: 50%;
  padding: 1.5rem;
  margin: 0 auto;
}

.third-section {
  margin-top: 0;
  width: 100% !important;
  /* padding-left: 10rem;
  padding-right: 10rem; */
  background: #325f98;
  border-radius: 0;
}

.button-download {
  width: 40%;
  background: #325f98;
  border-bottom: 2px solid #22355e;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 1rem;
  text-align: center;
  /* cursor: pointer; */
}

/* .button-download:hover {
  background: #2a5081;
} */

.text-download-app {
  color: #fefefe;
  font-weight: bold;
}

.button-tutorial {
  width: 30%;
  background: #6eb3bd;
  border-radius: 5px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.button-tutorial:hover {
  background: #64a2ac;
}

.button-billet:hover {
  background: #64a2ac;
}

.text-tutorial-app {
  position: absolute;
  color: #fefefe;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-phone {
  width: 13rem;
  padding-left: 1rem;
}

.instalacao {
  display: flex;
}

.text-instalacao {
  font-size: 16px;
  color: #fefefe;
  /* text-align: right; */
}

.last-section {
  margin-top: 0;
  width: 100% !important;
  /* padding-left: 10rem;
  padding-right: 10rem; */
  background: #325f98;
  border-radius: 0;
}

.last-section span {
  color: #fefefe !important;
}

.last-section hr {
  border-bottom: 0.7px solid #aadcff;
}

.group-button {
  display: flex;
  justify-content: center;
}

.button-billet {
  /* width: 50%; */
  background: #6eb3bd;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.link-boleto:first-child {
  margin-right: 0.5rem;
}

.link-boleto:last-child {
  margin-left: 0.5rem;
}

.boleto-section {
  padding-left: 30rem !important;
  padding-right: 30rem !important;
}

.info-billet {
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 5px;
}

.title-info-billet {
  font-weight: bold;
  color: #345ea8;
}

.text-contrato {
  line-height: 1.5;
}

.box-localizacao {
  background: #465E9F;
  width: 35rem;
  height: 15rem;
  position: absolute;
  top: 50%;
  opacity: 85%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.rectangle-section {
  margin-bottom: 9rem;
  position: relative;
}

.img-rectangle {
  position: absolute;
}

.icon-rectangle {
  width: 20%;
  position: absolute;
  left: 0;
  left: 50%;
  transform: translate(-50%, 37%);
}

.text-last-section {
  opacity: 0.8;
  line-height: 1.5;
}

.body-modal {
  overflow: scroll;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Poppins-Regular;
  background: linear-gradient(to bottom, #325F98, #3BABBC);
  height: 100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.body-modal-2 {
  overflow: scroll;
  padding-top: 2rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Poppins-Regular;
  background: linear-gradient(319.84deg, #2C7EB6 6.81%, #0E3F80 74.68%);
  height: 100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact100-form-btn {
  padding: 0 !important;
}

.contact100-form-btn-2 {
  padding: 0 !important;
}

.subtitle-offer-2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 51px;
  text-align: center;
  color: #FFFFFF;
}

.title-offer-2 {
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  font-size: 55px;
  color: transparent;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}


.title-offer-blur-2 {
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  font-size: 55px;
  color: transparent;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  color: transparent;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

.section-name {
  position: relative;
  padding-top: 8rem;
}

.box-offer-title-2 {
  margin: 0 auto;
}

.subtitle-box-offer-2 {
  font-weight: bold;
  font-size: 40px;
  line-height: 76px;
  color: #1D5F9B;
}

.box-offer-2 {
  margin: 0 auto;
  text-align: center;
  background-color: #ffff;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.announcement-benefits {
  font-weight: 800;
  font-size: 26px;
  line-height: 44px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.through {
  margin-top: 0.3rem;
  text-decoration: line-through;
  text-decoration-color: #fff !important;
}

.row-benefits  {
  margin-top: 1rem;
}

.box-square {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 18px;
  padding-top: 18px;
}

.square-price {
  text-align: center;
  background-color: #3DB9C5;
  height: 225px;
}

.img-rectangle {
  width: 100.5%;
}

.partners-section img {
  margin-left: 45px;
  margin-right: 45px;
}

.margin-0-auto  {
  margin: 0 auto;
}

.contact100-form-btn-2 {
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  height: 99px;
  border-radius: 10px;
  background-color: #3DB9C5;
  border-bottom: 5px solid #1F868F;
  margin-top: 5rem;
}

.square-price::after {
  content: "";
  position: absolute;
  top:50px; left: 0;
  background-color:inherit;
  padding-bottom:50%; width:57.7%;
  z-index:-1;
  
  -webkit-transform-origin:0 0;
  -ms-transform-origin:0 0;
  transform-origin:0 0;
  
  -webkit-transform: rotate(-30deg) skewX(30deg);;
  -ms-transform: rotate(-30deg) skewX(30deg);
  transform: rotate(-30deg) skewX(30deg);
}

.title-square {
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  color: #FFFFFF;
}

.subtitle-square {
  font-weight: 500;
  font-size: 16px;
  /* line-height: 32px; */
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.price-annuncement-square {
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.type-price-2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-right: 5px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF !important;
}

.price {
  font-weight: 900;
  font-size: 38px;
  line-height: 54px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

}

.use-credit {
  font-weight: 500;
  font-size: 14px;
  /* identical to box height */
  text-align: center;
  padding-top: 15px;
  color: #FFFFFF;
}

.price-use-credit {
  line-height: 1;
  font-weight: 800;
  font-size: 40px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.through .price {
  line-height: 0;
  font-size: 25px !important;
  font-weight: 600 !important;

}

.cents {
  font-weight: 900;
  font-size: 14px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.title-offer {
  font-size: 35px;
  color: #ffff;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
}

.description-offer {
  color: #ffff;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 765px) {
  .button-download {
    width: 100%;
  }
  .first-section {
    /* padding-left: 3rem;
    padding-right: 3rem; */
  }
  .third-section {
    /* padding-left: 3rem;
    padding-right: 3rem; */
  }
  .image-phone {
    padding: 0;
  }

  .col-info-billet {
    padding-bottom: 10px !important;
  }
}

@media (max-width: 512px) {
  .image-phone {
    padding: 0;
    margin-top: 1rem;
  }
}

@media (max-width: 1500px) {
  .instalacao {
    flex-direction: column;
  }
  .text-instalacao {
    text-align: center;
  }
  .button-tutorial {
    margin-top: 1rem;
    padding: 1.5rem 0;
    width: 50%;
  }
}


@media (max-width: 1810px) {
  .boleto-section {
    padding-left: 25rem !important;
    padding-right: 25rem !important;
  }
}

@media (max-width: 1700px) {
  .boleto-section {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }
}

@media (max-width: 1015px) {
  .title-offer {
    font-size: 30px;
  }
  .description-offer {
    font-size: 17px;
  }
}

@media (max-width: 870px) {
  .title-offer {
    font-size: 27px;
  }
  .description-offer {
    font-size: 15px;
  }
}

@media (max-width: 790px) {
  .title-offer {
    font-size: 24px;
  }
  .description-offer {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .title-offer {
    font-size: 20px;
  }
}


@media (max-width: 1400px) {
  .boleto-section {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
}

@media (max-width: 1000px) {
  .boleto-section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (max-width: 510px) {
  .text-instalacao {
    font-size: 14px;
  }
  .input100 {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .last-section {
    padding-top: 0;
  }

  .divisor-logo {
    padding-bottom: 2rem !important;
  }
  .text-button-offer {
    font-size: 12px;
  }
  .img-consulta {
    max-width: 80%;
  }
  .col-margin {
    margin: 0 auto;
  }
  .rectangle-section {
    margin-bottom: 12rem;
  }
}

@media (max-width: 400px) {
  .boleto-section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .box-localizacao {
    width: 25rem;
    height: 15rem;
  }

  .text-instalacao {
    font-size: 10px;
  }

  .preco-adesao {
    text-align: center;
  }

  .preco-mensalidade {
    padding-top: 1rem
  }

  .mes {
    padding-top: 0.5rem;
  }

  .btn-forma-pagamento {
    /* width: 8.7rem !important; */
  }

  .btn-forma-pagamento-active {
    /* width: 8.7rem !important; */
  }

  /* .group-discount {
    display: none
  } */

  .text-payment {
    font-size: 12px;
  }

  .text-payment-up-active {
    font-size: 13px !important;
  }

  .wrap-contact100-form-btn {
    width: 60% !important;
  }

  .wrap-contact100-form-btn-2 {
    width: 60% !important;
  }
}

@media (max-width: 530px) {
  .btn-forma-pagamento {
    width: 11rem;
  }
  .btn-forma-pagamento-active {
    width: 11rem;
  }

  .btn-forma-pagamento-up-active {
    width: 11rem;
  }

  .btn-forma-mais-vendido-up-active {
    width: 11rem;
  }

  .text-payment-up-active {
    font-size: 13px !important;
  }

  .btn-forma-pagamento-up {
    width: 11rem;
  }

  .separator-desc-pagamento {
    padding-right: 1rem;
  }
}

@media (max-width: 400px) {
  .btn-forma-pagamento {
    width: 11rem;
  }
  .btn-forma-pagamento-active {
    width: 11rem;
  }

  .btn-forma-pagamento-up-active {
    width: 11rem;
  }

  .btn-forma-mais-vendido-up-active {
    width: 11rem;
  }

  .btn-forma-pagamento-up {
    width: 11rem;
  }
}

@media (max-width: 350px) {
  .btn-forma-pagamento {
    width: 9.5rem;
  }
  .btn-forma-pagamento-active {
    width: 9.5rem;
  }

  .btn-forma-pagamento-up-active {
    width: 9.5rem;
  }

  .btn-forma-mais-vendido-up-active {
    width: 9.5rem;
  }

  .btn-forma-pagamento-up {
    width: 9.5rem;
  }
  .text-payment-up-active {
    font-size: 11px !important;
  }
  .text-payment {
    font-size: 12px !important;
  }
}

@media (max-width: 300px) {
  .btn-forma-pagamento {
    width: 8rem;
  }
  .btn-forma-pagamento-active {
    width: 8rem;
  }

  .btn-forma-pagamento-up-active {
    width: 8rem;
  }

  .btn-forma-mais-vendido-up-active {
    width: 8rem;
  }

  .btn-forma-pagamento-up {
    width: 8rem;
  }

}